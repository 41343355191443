const cc = "required",
  cd = "type",
  ce = "conditions",
  cf = "fn",
  cg = "argv",
  ch = "ref",
  ci = "assign",
  cj = "url",
  ck = "properties",
  cl = "backend",
  cm = "authSchemes",
  cn = "disableDoubleEncoding",
  co = "signingName",
  cp = "signingRegion",
  cq = "headers";
const a = false,
  b = true,
  c = "isSet",
  d = "booleanEquals",
  e = "error",
  f = "aws.partition",
  g = "stringEquals",
  h = "getAttr",
  i = "name",
  j = "substring",
  k = "bucketSuffix",
  l = "parseURL",
  m = "{url#scheme}://{url#authority}/{uri_encoded_bucket}{url#path}",
  n = "endpoint",
  o = "tree",
  p = "aws.isVirtualHostableS3Bucket",
  q = "{url#scheme}://{Bucket}.{url#authority}{url#path}",
  r = "not",
  s = "{url#scheme}://{url#authority}{url#path}",
  t = "hardwareType",
  u = "regionPrefix",
  v = "bucketAliasSuffix",
  w = "outpostId",
  x = "isValidHostLabel",
  y = "s3-outposts",
  z = "s3",
  A = "{url#scheme}://{url#authority}{url#normalizedPath}{Bucket}",
  B = "https://{Bucket}.s3-accelerate.{partitionResult#dnsSuffix}",
  C = "https://{Bucket}.s3.{partitionResult#dnsSuffix}",
  D = "aws.parseArn",
  E = "bucketArn",
  F = "arnType",
  G = "",
  H = "s3-object-lambda",
  I = "accesspoint",
  J = "accessPointName",
  K = "{url#scheme}://{accessPointName}-{bucketArn#accountId}.{url#authority}{url#path}",
  L = "mrapPartition",
  M = "outpostType",
  N = "arnPrefix",
  O = "{url#scheme}://{url#authority}{url#normalizedPath}{uri_encoded_bucket}",
  P = "https://s3.{partitionResult#dnsSuffix}/{uri_encoded_bucket}",
  Q = "https://s3.{partitionResult#dnsSuffix}",
  R = {
    [cc]: false,
    [cd]: "String"
  },
  S = {
    [cc]: true,
    "default": false,
    [cd]: "Boolean"
  },
  T = {
    [cc]: false,
    [cd]: "Boolean"
  },
  U = {
    [cf]: d,
    [cg]: [{
      [ch]: "Accelerate"
    }, true]
  },
  V = {
    [cf]: d,
    [cg]: [{
      [ch]: "UseFIPS"
    }, true]
  },
  W = {
    [cf]: d,
    [cg]: [{
      [ch]: "UseDualStack"
    }, true]
  },
  X = {
    [cf]: c,
    [cg]: [{
      [ch]: "Endpoint"
    }]
  },
  Y = {
    [cf]: f,
    [cg]: [{
      [ch]: "Region"
    }],
    [ci]: "partitionResult"
  },
  Z = {
    [cf]: g,
    [cg]: [{
      [cf]: h,
      [cg]: [{
        [ch]: "partitionResult"
      }, i]
    }, "aws-cn"]
  },
  aa = {
    [cf]: c,
    [cg]: [{
      [ch]: "Bucket"
    }]
  },
  ab = {
    [ch]: "Bucket"
  },
  ac = {
    [cf]: l,
    [cg]: [{
      [ch]: "Endpoint"
    }],
    [ci]: "url"
  },
  ad = {
    [cf]: d,
    [cg]: [{
      [cf]: h,
      [cg]: [{
        [ch]: "url"
      }, "isIp"]
    }, true]
  },
  ae = {
    [ch]: "url"
  },
  af = {
    [cf]: "uriEncode",
    [cg]: [ab],
    [ci]: "uri_encoded_bucket"
  },
  ag = {
    [cl]: "S3Express",
    [cm]: [{
      [cn]: true,
      [i]: "sigv4",
      [co]: "s3express",
      [cp]: "{Region}"
    }]
  },
  ah = {},
  ai = {
    [cf]: p,
    [cg]: [ab, false]
  },
  aj = {
    [e]: "S3Express bucket name is not a valid virtual hostable name.",
    [cd]: e
  },
  ak = {
    [cl]: "S3Express",
    [cm]: [{
      [cn]: true,
      [i]: "sigv4-s3express",
      [co]: "s3express",
      [cp]: "{Region}"
    }]
  },
  al = {
    [cf]: c,
    [cg]: [{
      [ch]: "UseS3ExpressControlEndpoint"
    }]
  },
  am = {
    [cf]: d,
    [cg]: [{
      [ch]: "UseS3ExpressControlEndpoint"
    }, true]
  },
  an = {
    [cf]: r,
    [cg]: [X]
  },
  ao = {
    [e]: "Unrecognized S3Express bucket name format.",
    [cd]: e
  },
  ap = {
    [cf]: r,
    [cg]: [aa]
  },
  aq = {
    [ch]: t
  },
  ar = {
    [ce]: [an],
    [e]: "Expected a endpoint to be specified but no endpoint was found",
    [cd]: e
  },
  as = {
    [cm]: [{
      [cn]: true,
      [i]: "sigv4",
      [co]: y,
      [cp]: "{Region}"
    }]
  },
  at = {
    [cf]: d,
    [cg]: [{
      [ch]: "ForcePathStyle"
    }, false]
  },
  au = {
    [ch]: "ForcePathStyle"
  },
  av = {
    [cf]: d,
    [cg]: [{
      [ch]: "Accelerate"
    }, false]
  },
  aw = {
    [cf]: g,
    [cg]: [{
      [ch]: "Region"
    }, "aws-global"]
  },
  ax = {
    [cm]: [{
      [cn]: true,
      [i]: "sigv4",
      [co]: z,
      [cp]: "us-east-1"
    }]
  },
  ay = {
    [cf]: r,
    [cg]: [aw]
  },
  az = {
    [cf]: d,
    [cg]: [{
      [ch]: "UseGlobalEndpoint"
    }, true]
  },
  aA = {
    [cj]: "https://{Bucket}.s3-fips.dualstack.{Region}.{partitionResult#dnsSuffix}",
    [ck]: {
      [cm]: [{
        [cn]: true,
        [i]: "sigv4",
        [co]: z,
        [cp]: "{Region}"
      }]
    },
    [cq]: {}
  },
  aB = {
    [cm]: [{
      [cn]: true,
      [i]: "sigv4",
      [co]: z,
      [cp]: "{Region}"
    }]
  },
  aC = {
    [cf]: d,
    [cg]: [{
      [ch]: "UseGlobalEndpoint"
    }, false]
  },
  aD = {
    [cf]: d,
    [cg]: [{
      [ch]: "UseDualStack"
    }, false]
  },
  aE = {
    [cj]: "https://{Bucket}.s3-fips.{Region}.{partitionResult#dnsSuffix}",
    [ck]: aB,
    [cq]: {}
  },
  aF = {
    [cf]: d,
    [cg]: [{
      [ch]: "UseFIPS"
    }, false]
  },
  aG = {
    [cj]: "https://{Bucket}.s3-accelerate.dualstack.{partitionResult#dnsSuffix}",
    [ck]: aB,
    [cq]: {}
  },
  aH = {
    [cj]: "https://{Bucket}.s3.dualstack.{Region}.{partitionResult#dnsSuffix}",
    [ck]: aB,
    [cq]: {}
  },
  aI = {
    [cf]: d,
    [cg]: [{
      [cf]: h,
      [cg]: [ae, "isIp"]
    }, false]
  },
  aJ = {
    [cj]: A,
    [ck]: aB,
    [cq]: {}
  },
  aK = {
    [cj]: q,
    [ck]: aB,
    [cq]: {}
  },
  aL = {
    [n]: aK,
    [cd]: n
  },
  aM = {
    [cj]: B,
    [ck]: aB,
    [cq]: {}
  },
  aN = {
    [cj]: "https://{Bucket}.s3.{Region}.{partitionResult#dnsSuffix}",
    [ck]: aB,
    [cq]: {}
  },
  aO = {
    [e]: "Invalid region: region was not a valid DNS name.",
    [cd]: e
  },
  aP = {
    [ch]: E
  },
  aQ = {
    [ch]: F
  },
  aR = {
    [cf]: h,
    [cg]: [aP, "service"]
  },
  aS = {
    [ch]: J
  },
  aT = {
    [ce]: [W],
    [e]: "S3 Object Lambda does not support Dual-stack",
    [cd]: e
  },
  aU = {
    [ce]: [U],
    [e]: "S3 Object Lambda does not support S3 Accelerate",
    [cd]: e
  },
  aV = {
    [ce]: [{
      [cf]: c,
      [cg]: [{
        [ch]: "DisableAccessPoints"
      }]
    }, {
      [cf]: d,
      [cg]: [{
        [ch]: "DisableAccessPoints"
      }, true]
    }],
    [e]: "Access points are not supported for this operation",
    [cd]: e
  },
  aW = {
    [ce]: [{
      [cf]: c,
      [cg]: [{
        [ch]: "UseArnRegion"
      }]
    }, {
      [cf]: d,
      [cg]: [{
        [ch]: "UseArnRegion"
      }, false]
    }, {
      [cf]: r,
      [cg]: [{
        [cf]: g,
        [cg]: [{
          [cf]: h,
          [cg]: [aP, "region"]
        }, "{Region}"]
      }]
    }],
    [e]: "Invalid configuration: region from ARN `{bucketArn#region}` does not match client region `{Region}` and UseArnRegion is `false`",
    [cd]: e
  },
  aX = {
    [cf]: h,
    [cg]: [{
      [ch]: "bucketPartition"
    }, i]
  },
  aY = {
    [cf]: h,
    [cg]: [aP, "accountId"]
  },
  aZ = {
    [cm]: [{
      [cn]: true,
      [i]: "sigv4",
      [co]: H,
      [cp]: "{bucketArn#region}"
    }]
  },
  ba = {
    [e]: "Invalid ARN: The access point name may only contain a-z, A-Z, 0-9 and `-`. Found: `{accessPointName}`",
    [cd]: e
  },
  bb = {
    [e]: "Invalid ARN: The account id may only contain a-z, A-Z, 0-9 and `-`. Found: `{bucketArn#accountId}`",
    [cd]: e
  },
  bc = {
    [e]: "Invalid region in ARN: `{bucketArn#region}` (invalid DNS name)",
    [cd]: e
  },
  bd = {
    [e]: "Client was configured for partition `{partitionResult#name}` but ARN (`{Bucket}`) has `{bucketPartition#name}`",
    [cd]: e
  },
  be = {
    [e]: "Invalid ARN: The ARN may only contain a single resource component after `accesspoint`.",
    [cd]: e
  },
  bf = {
    [e]: "Invalid ARN: Expected a resource of the format `accesspoint:<accesspoint name>` but no name was provided",
    [cd]: e
  },
  bg = {
    [cm]: [{
      [cn]: true,
      [i]: "sigv4",
      [co]: z,
      [cp]: "{bucketArn#region}"
    }]
  },
  bh = {
    [cm]: [{
      [cn]: true,
      [i]: "sigv4",
      [co]: y,
      [cp]: "{bucketArn#region}"
    }]
  },
  bi = {
    [cf]: D,
    [cg]: [ab]
  },
  bj = {
    [cj]: "https://s3-fips.dualstack.{Region}.{partitionResult#dnsSuffix}/{uri_encoded_bucket}",
    [ck]: aB,
    [cq]: {}
  },
  bk = {
    [cj]: "https://s3-fips.{Region}.{partitionResult#dnsSuffix}/{uri_encoded_bucket}",
    [ck]: aB,
    [cq]: {}
  },
  bl = {
    [cj]: "https://s3.dualstack.{Region}.{partitionResult#dnsSuffix}/{uri_encoded_bucket}",
    [ck]: aB,
    [cq]: {}
  },
  bm = {
    [cj]: O,
    [ck]: aB,
    [cq]: {}
  },
  bn = {
    [cj]: "https://s3.{Region}.{partitionResult#dnsSuffix}/{uri_encoded_bucket}",
    [ck]: aB,
    [cq]: {}
  },
  bo = {
    [ch]: "UseObjectLambdaEndpoint"
  },
  bp = {
    [cm]: [{
      [cn]: true,
      [i]: "sigv4",
      [co]: H,
      [cp]: "{Region}"
    }]
  },
  bq = {
    [cj]: "https://s3-fips.dualstack.{Region}.{partitionResult#dnsSuffix}",
    [ck]: aB,
    [cq]: {}
  },
  br = {
    [cj]: "https://s3-fips.{Region}.{partitionResult#dnsSuffix}",
    [ck]: aB,
    [cq]: {}
  },
  bs = {
    [cj]: "https://s3.dualstack.{Region}.{partitionResult#dnsSuffix}",
    [ck]: aB,
    [cq]: {}
  },
  bt = {
    [cj]: s,
    [ck]: aB,
    [cq]: {}
  },
  bu = {
    [cj]: "https://s3.{Region}.{partitionResult#dnsSuffix}",
    [ck]: aB,
    [cq]: {}
  },
  bv = [{
    [ch]: "Region"
  }],
  bw = [{
    [ch]: "Endpoint"
  }],
  bx = [ab],
  by = [W],
  bz = [U],
  bA = [X, ac],
  bB = [{
    [cf]: c,
    [cg]: [{
      [ch]: "DisableS3ExpressSessionAuth"
    }]
  }, {
    [cf]: d,
    [cg]: [{
      [ch]: "DisableS3ExpressSessionAuth"
    }, true]
  }],
  bC = [ad],
  bD = [af],
  bE = [ai],
  bF = [V],
  bG = [{
    [cf]: j,
    [cg]: [ab, 6, 14, true],
    [ci]: "s3expressAvailabilityZoneId"
  }, {
    [cf]: j,
    [cg]: [ab, 14, 16, true],
    [ci]: "s3expressAvailabilityZoneDelim"
  }, {
    [cf]: g,
    [cg]: [{
      [ch]: "s3expressAvailabilityZoneDelim"
    }, "--"]
  }],
  bH = [{
    [ce]: [V],
    [n]: {
      [cj]: "https://{Bucket}.s3express-fips-{s3expressAvailabilityZoneId}.{Region}.amazonaws.com",
      [ck]: ag,
      [cq]: {}
    },
    [cd]: n
  }, {
    [n]: {
      [cj]: "https://{Bucket}.s3express-{s3expressAvailabilityZoneId}.{Region}.amazonaws.com",
      [ck]: ag,
      [cq]: {}
    },
    [cd]: n
  }],
  bI = [{
    [cf]: j,
    [cg]: [ab, 6, 15, true],
    [ci]: "s3expressAvailabilityZoneId"
  }, {
    [cf]: j,
    [cg]: [ab, 15, 17, true],
    [ci]: "s3expressAvailabilityZoneDelim"
  }, {
    [cf]: g,
    [cg]: [{
      [ch]: "s3expressAvailabilityZoneDelim"
    }, "--"]
  }],
  bJ = [{
    [ce]: [V],
    [n]: {
      [cj]: "https://{Bucket}.s3express-fips-{s3expressAvailabilityZoneId}.{Region}.amazonaws.com",
      [ck]: ak,
      [cq]: {}
    },
    [cd]: n
  }, {
    [n]: {
      [cj]: "https://{Bucket}.s3express-{s3expressAvailabilityZoneId}.{Region}.amazonaws.com",
      [ck]: ak,
      [cq]: {}
    },
    [cd]: n
  }],
  bK = [aa],
  bL = [{
    [cf]: x,
    [cg]: [{
      [ch]: w
    }, false]
  }],
  bM = [{
    [cf]: g,
    [cg]: [{
      [ch]: u
    }, "beta"]
  }],
  bN = [Y],
  bO = [{
    [cf]: x,
    [cg]: [{
      [ch]: "Region"
    }, false]
  }],
  bP = [{
    [cf]: g,
    [cg]: [{
      [ch]: "Region"
    }, "us-east-1"]
  }],
  bQ = [{
    [cf]: g,
    [cg]: [aQ, I]
  }],
  bR = [{
    [cf]: h,
    [cg]: [aP, "resourceId[1]"],
    [ci]: J
  }, {
    [cf]: r,
    [cg]: [{
      [cf]: g,
      [cg]: [aS, G]
    }]
  }],
  bS = [aP, "resourceId[1]"],
  bT = [{
    [cf]: r,
    [cg]: [{
      [cf]: g,
      [cg]: [{
        [cf]: h,
        [cg]: [aP, "region"]
      }, G]
    }]
  }],
  bU = [{
    [cf]: r,
    [cg]: [{
      [cf]: c,
      [cg]: [{
        [cf]: h,
        [cg]: [aP, "resourceId[2]"]
      }]
    }]
  }],
  bV = [aP, "resourceId[2]"],
  bW = [{
    [cf]: f,
    [cg]: [{
      [cf]: h,
      [cg]: [aP, "region"]
    }],
    [ci]: "bucketPartition"
  }],
  bX = [{
    [cf]: g,
    [cg]: [aX, {
      [cf]: h,
      [cg]: [{
        [ch]: "partitionResult"
      }, i]
    }]
  }],
  bY = [{
    [cf]: x,
    [cg]: [{
      [cf]: h,
      [cg]: [aP, "region"]
    }, true]
  }],
  bZ = [{
    [cf]: x,
    [cg]: [aY, false]
  }],
  ca = [{
    [cf]: x,
    [cg]: [aS, false]
  }],
  cb = [{
    [cf]: x,
    [cg]: [{
      [ch]: "Region"
    }, true]
  }];
const _data = {
  version: "1.0",
  parameters: {
    Bucket: R,
    Region: R,
    UseFIPS: S,
    UseDualStack: S,
    Endpoint: R,
    ForcePathStyle: S,
    Accelerate: S,
    UseGlobalEndpoint: S,
    UseObjectLambdaEndpoint: T,
    Key: R,
    Prefix: R,
    DisableAccessPoints: T,
    DisableMultiRegionAccessPoints: S,
    UseArnRegion: T,
    UseS3ExpressControlEndpoint: T,
    DisableS3ExpressSessionAuth: T
  },
  rules: [{
    [ce]: [{
      [cf]: c,
      [cg]: bv
    }],
    rules: [{
      [ce]: [U, V],
      error: "Accelerate cannot be used with FIPS",
      [cd]: e
    }, {
      [ce]: [W, X],
      error: "Cannot set dual-stack in combination with a custom endpoint.",
      [cd]: e
    }, {
      [ce]: [X, V],
      error: "A custom endpoint cannot be combined with FIPS",
      [cd]: e
    }, {
      [ce]: [X, U],
      error: "A custom endpoint cannot be combined with S3 Accelerate",
      [cd]: e
    }, {
      [ce]: [V, Y, Z],
      error: "Partition does not support FIPS",
      [cd]: e
    }, {
      [ce]: [aa, {
        [cf]: j,
        [cg]: [ab, 0, 6, b],
        [ci]: k
      }, {
        [cf]: g,
        [cg]: [{
          [ch]: k
        }, "--x-s3"]
      }],
      rules: [{
        [ce]: by,
        error: "S3Express does not support Dual-stack.",
        [cd]: e
      }, {
        [ce]: bz,
        error: "S3Express does not support S3 Accelerate.",
        [cd]: e
      }, {
        [ce]: bA,
        rules: [{
          [ce]: bB,
          rules: [{
            [ce]: bC,
            rules: [{
              [ce]: bD,
              rules: [{
                endpoint: {
                  [cj]: m,
                  [ck]: ag,
                  [cq]: ah
                },
                [cd]: n
              }],
              [cd]: o
            }],
            [cd]: o
          }, {
            [ce]: bE,
            rules: [{
              endpoint: {
                [cj]: q,
                [ck]: ag,
                [cq]: ah
              },
              [cd]: n
            }],
            [cd]: o
          }, aj],
          [cd]: o
        }, {
          [ce]: bC,
          rules: [{
            [ce]: bD,
            rules: [{
              endpoint: {
                [cj]: m,
                [ck]: ak,
                [cq]: ah
              },
              [cd]: n
            }],
            [cd]: o
          }],
          [cd]: o
        }, {
          [ce]: bE,
          rules: [{
            endpoint: {
              [cj]: q,
              [ck]: ak,
              [cq]: ah
            },
            [cd]: n
          }],
          [cd]: o
        }, aj],
        [cd]: o
      }, {
        [ce]: [al, am],
        rules: [{
          [ce]: [af, an],
          rules: [{
            [ce]: bF,
            endpoint: {
              [cj]: "https://s3express-control-fips.{Region}.amazonaws.com/{uri_encoded_bucket}",
              [ck]: ag,
              [cq]: ah
            },
            [cd]: n
          }, {
            endpoint: {
              [cj]: "https://s3express-control.{Region}.amazonaws.com/{uri_encoded_bucket}",
              [ck]: ag,
              [cq]: ah
            },
            [cd]: n
          }],
          [cd]: o
        }],
        [cd]: o
      }, {
        [ce]: bE,
        rules: [{
          [ce]: bB,
          rules: [{
            [ce]: bG,
            rules: bH,
            [cd]: o
          }, {
            [ce]: bI,
            rules: bH,
            [cd]: o
          }, ao],
          [cd]: o
        }, {
          [ce]: bG,
          rules: bJ,
          [cd]: o
        }, {
          [ce]: bI,
          rules: bJ,
          [cd]: o
        }, ao],
        [cd]: o
      }, aj],
      [cd]: o
    }, {
      [ce]: [ap, al, am],
      rules: [{
        [ce]: bA,
        endpoint: {
          [cj]: s,
          [ck]: ag,
          [cq]: ah
        },
        [cd]: n
      }, {
        [ce]: bF,
        endpoint: {
          [cj]: "https://s3express-control-fips.{Region}.amazonaws.com",
          [ck]: ag,
          [cq]: ah
        },
        [cd]: n
      }, {
        endpoint: {
          [cj]: "https://s3express-control.{Region}.amazonaws.com",
          [ck]: ag,
          [cq]: ah
        },
        [cd]: n
      }],
      [cd]: o
    }, {
      [ce]: [aa, {
        [cf]: j,
        [cg]: [ab, 49, 50, b],
        [ci]: t
      }, {
        [cf]: j,
        [cg]: [ab, 8, 12, b],
        [ci]: u
      }, {
        [cf]: j,
        [cg]: [ab, 0, 7, b],
        [ci]: v
      }, {
        [cf]: j,
        [cg]: [ab, 32, 49, b],
        [ci]: w
      }, {
        [cf]: f,
        [cg]: bv,
        [ci]: "regionPartition"
      }, {
        [cf]: g,
        [cg]: [{
          [ch]: v
        }, "--op-s3"]
      }],
      rules: [{
        [ce]: bL,
        rules: [{
          [ce]: [{
            [cf]: g,
            [cg]: [aq, "e"]
          }],
          rules: [{
            [ce]: bM,
            rules: [ar, {
              [ce]: bA,
              endpoint: {
                [cj]: "https://{Bucket}.ec2.{url#authority}",
                [ck]: as,
                [cq]: ah
              },
              [cd]: n
            }],
            [cd]: o
          }, {
            endpoint: {
              [cj]: "https://{Bucket}.ec2.s3-outposts.{Region}.{regionPartition#dnsSuffix}",
              [ck]: as,
              [cq]: ah
            },
            [cd]: n
          }],
          [cd]: o
        }, {
          [ce]: [{
            [cf]: g,
            [cg]: [aq, "o"]
          }],
          rules: [{
            [ce]: bM,
            rules: [ar, {
              [ce]: bA,
              endpoint: {
                [cj]: "https://{Bucket}.op-{outpostId}.{url#authority}",
                [ck]: as,
                [cq]: ah
              },
              [cd]: n
            }],
            [cd]: o
          }, {
            endpoint: {
              [cj]: "https://{Bucket}.op-{outpostId}.s3-outposts.{Region}.{regionPartition#dnsSuffix}",
              [ck]: as,
              [cq]: ah
            },
            [cd]: n
          }],
          [cd]: o
        }, {
          error: "Unrecognized hardware type: \"Expected hardware type o or e but got {hardwareType}\"",
          [cd]: e
        }],
        [cd]: o
      }, {
        error: "Invalid ARN: The outpost Id must only contain a-z, A-Z, 0-9 and `-`.",
        [cd]: e
      }],
      [cd]: o
    }, {
      [ce]: bK,
      rules: [{
        [ce]: [X, {
          [cf]: r,
          [cg]: [{
            [cf]: c,
            [cg]: [{
              [cf]: l,
              [cg]: bw
            }]
          }]
        }],
        error: "Custom endpoint `{Endpoint}` was not a valid URI",
        [cd]: e
      }, {
        [ce]: [at, ai],
        rules: [{
          [ce]: bN,
          rules: [{
            [ce]: bO,
            rules: [{
              [ce]: [U, Z],
              error: "S3 Accelerate cannot be used in this region",
              [cd]: e
            }, {
              [ce]: [W, V, av, an, aw],
              endpoint: {
                [cj]: "https://{Bucket}.s3-fips.dualstack.us-east-1.{partitionResult#dnsSuffix}",
                [ck]: ax,
                [cq]: ah
              },
              [cd]: n
            }, {
              [ce]: [W, V, av, an, ay, az],
              rules: [{
                endpoint: aA,
                [cd]: n
              }],
              [cd]: o
            }, {
              [ce]: [W, V, av, an, ay, aC],
              endpoint: aA,
              [cd]: n
            }, {
              [ce]: [aD, V, av, an, aw],
              endpoint: {
                [cj]: "https://{Bucket}.s3-fips.us-east-1.{partitionResult#dnsSuffix}",
                [ck]: ax,
                [cq]: ah
              },
              [cd]: n
            }, {
              [ce]: [aD, V, av, an, ay, az],
              rules: [{
                endpoint: aE,
                [cd]: n
              }],
              [cd]: o
            }, {
              [ce]: [aD, V, av, an, ay, aC],
              endpoint: aE,
              [cd]: n
            }, {
              [ce]: [W, aF, U, an, aw],
              endpoint: {
                [cj]: "https://{Bucket}.s3-accelerate.dualstack.us-east-1.{partitionResult#dnsSuffix}",
                [ck]: ax,
                [cq]: ah
              },
              [cd]: n
            }, {
              [ce]: [W, aF, U, an, ay, az],
              rules: [{
                endpoint: aG,
                [cd]: n
              }],
              [cd]: o
            }, {
              [ce]: [W, aF, U, an, ay, aC],
              endpoint: aG,
              [cd]: n
            }, {
              [ce]: [W, aF, av, an, aw],
              endpoint: {
                [cj]: "https://{Bucket}.s3.dualstack.us-east-1.{partitionResult#dnsSuffix}",
                [ck]: ax,
                [cq]: ah
              },
              [cd]: n
            }, {
              [ce]: [W, aF, av, an, ay, az],
              rules: [{
                endpoint: aH,
                [cd]: n
              }],
              [cd]: o
            }, {
              [ce]: [W, aF, av, an, ay, aC],
              endpoint: aH,
              [cd]: n
            }, {
              [ce]: [aD, aF, av, X, ac, ad, aw],
              endpoint: {
                [cj]: A,
                [ck]: ax,
                [cq]: ah
              },
              [cd]: n
            }, {
              [ce]: [aD, aF, av, X, ac, aI, aw],
              endpoint: {
                [cj]: q,
                [ck]: ax,
                [cq]: ah
              },
              [cd]: n
            }, {
              [ce]: [aD, aF, av, X, ac, ad, ay, az],
              rules: [{
                [ce]: bP,
                endpoint: aJ,
                [cd]: n
              }, {
                endpoint: aJ,
                [cd]: n
              }],
              [cd]: o
            }, {
              [ce]: [aD, aF, av, X, ac, aI, ay, az],
              rules: [{
                [ce]: bP,
                endpoint: aK,
                [cd]: n
              }, aL],
              [cd]: o
            }, {
              [ce]: [aD, aF, av, X, ac, ad, ay, aC],
              endpoint: aJ,
              [cd]: n
            }, {
              [ce]: [aD, aF, av, X, ac, aI, ay, aC],
              endpoint: aK,
              [cd]: n
            }, {
              [ce]: [aD, aF, U, an, aw],
              endpoint: {
                [cj]: B,
                [ck]: ax,
                [cq]: ah
              },
              [cd]: n
            }, {
              [ce]: [aD, aF, U, an, ay, az],
              rules: [{
                [ce]: bP,
                endpoint: aM,
                [cd]: n
              }, {
                endpoint: aM,
                [cd]: n
              }],
              [cd]: o
            }, {
              [ce]: [aD, aF, U, an, ay, aC],
              endpoint: aM,
              [cd]: n
            }, {
              [ce]: [aD, aF, av, an, aw],
              endpoint: {
                [cj]: C,
                [ck]: ax,
                [cq]: ah
              },
              [cd]: n
            }, {
              [ce]: [aD, aF, av, an, ay, az],
              rules: [{
                [ce]: bP,
                endpoint: {
                  [cj]: C,
                  [ck]: aB,
                  [cq]: ah
                },
                [cd]: n
              }, {
                endpoint: aN,
                [cd]: n
              }],
              [cd]: o
            }, {
              [ce]: [aD, aF, av, an, ay, aC],
              endpoint: aN,
              [cd]: n
            }],
            [cd]: o
          }, aO],
          [cd]: o
        }],
        [cd]: o
      }, {
        [ce]: [X, ac, {
          [cf]: g,
          [cg]: [{
            [cf]: h,
            [cg]: [ae, "scheme"]
          }, "http"]
        }, {
          [cf]: p,
          [cg]: [ab, b]
        }, at, aF, aD, av],
        rules: [{
          [ce]: bN,
          rules: [{
            [ce]: bO,
            rules: [aL],
            [cd]: o
          }, aO],
          [cd]: o
        }],
        [cd]: o
      }, {
        [ce]: [at, {
          [cf]: D,
          [cg]: bx,
          [ci]: E
        }],
        rules: [{
          [ce]: [{
            [cf]: h,
            [cg]: [aP, "resourceId[0]"],
            [ci]: F
          }, {
            [cf]: r,
            [cg]: [{
              [cf]: g,
              [cg]: [aQ, G]
            }]
          }],
          rules: [{
            [ce]: [{
              [cf]: g,
              [cg]: [aR, H]
            }],
            rules: [{
              [ce]: bQ,
              rules: [{
                [ce]: bR,
                rules: [aT, aU, {
                  [ce]: bT,
                  rules: [aV, {
                    [ce]: bU,
                    rules: [aW, {
                      [ce]: bW,
                      rules: [{
                        [ce]: bN,
                        rules: [{
                          [ce]: bX,
                          rules: [{
                            [ce]: bY,
                            rules: [{
                              [ce]: [{
                                [cf]: g,
                                [cg]: [aY, G]
                              }],
                              error: "Invalid ARN: Missing account id",
                              [cd]: e
                            }, {
                              [ce]: bZ,
                              rules: [{
                                [ce]: ca,
                                rules: [{
                                  [ce]: bA,
                                  endpoint: {
                                    [cj]: K,
                                    [ck]: aZ,
                                    [cq]: ah
                                  },
                                  [cd]: n
                                }, {
                                  [ce]: bF,
                                  endpoint: {
                                    [cj]: "https://{accessPointName}-{bucketArn#accountId}.s3-object-lambda-fips.{bucketArn#region}.{bucketPartition#dnsSuffix}",
                                    [ck]: aZ,
                                    [cq]: ah
                                  },
                                  [cd]: n
                                }, {
                                  endpoint: {
                                    [cj]: "https://{accessPointName}-{bucketArn#accountId}.s3-object-lambda.{bucketArn#region}.{bucketPartition#dnsSuffix}",
                                    [ck]: aZ,
                                    [cq]: ah
                                  },
                                  [cd]: n
                                }],
                                [cd]: o
                              }, ba],
                              [cd]: o
                            }, bb],
                            [cd]: o
                          }, bc],
                          [cd]: o
                        }, bd],
                        [cd]: o
                      }],
                      [cd]: o
                    }],
                    [cd]: o
                  }, be],
                  [cd]: o
                }, {
                  error: "Invalid ARN: bucket ARN is missing a region",
                  [cd]: e
                }],
                [cd]: o
              }, bf],
              [cd]: o
            }, {
              error: "Invalid ARN: Object Lambda ARNs only support `accesspoint` arn types, but found: `{arnType}`",
              [cd]: e
            }],
            [cd]: o
          }, {
            [ce]: bQ,
            rules: [{
              [ce]: bR,
              rules: [{
                [ce]: bT,
                rules: [{
                  [ce]: bQ,
                  rules: [{
                    [ce]: bT,
                    rules: [aV, {
                      [ce]: bU,
                      rules: [aW, {
                        [ce]: bW,
                        rules: [{
                          [ce]: bN,
                          rules: [{
                            [ce]: [{
                              [cf]: g,
                              [cg]: [aX, "{partitionResult#name}"]
                            }],
                            rules: [{
                              [ce]: bY,
                              rules: [{
                                [ce]: [{
                                  [cf]: g,
                                  [cg]: [aR, z]
                                }],
                                rules: [{
                                  [ce]: bZ,
                                  rules: [{
                                    [ce]: ca,
                                    rules: [{
                                      [ce]: bz,
                                      error: "Access Points do not support S3 Accelerate",
                                      [cd]: e
                                    }, {
                                      [ce]: [V, W],
                                      endpoint: {
                                        [cj]: "https://{accessPointName}-{bucketArn#accountId}.s3-accesspoint-fips.dualstack.{bucketArn#region}.{bucketPartition#dnsSuffix}",
                                        [ck]: bg,
                                        [cq]: ah
                                      },
                                      [cd]: n
                                    }, {
                                      [ce]: [V, aD],
                                      endpoint: {
                                        [cj]: "https://{accessPointName}-{bucketArn#accountId}.s3-accesspoint-fips.{bucketArn#region}.{bucketPartition#dnsSuffix}",
                                        [ck]: bg,
                                        [cq]: ah
                                      },
                                      [cd]: n
                                    }, {
                                      [ce]: [aF, W],
                                      endpoint: {
                                        [cj]: "https://{accessPointName}-{bucketArn#accountId}.s3-accesspoint.dualstack.{bucketArn#region}.{bucketPartition#dnsSuffix}",
                                        [ck]: bg,
                                        [cq]: ah
                                      },
                                      [cd]: n
                                    }, {
                                      [ce]: [aF, aD, X, ac],
                                      endpoint: {
                                        [cj]: K,
                                        [ck]: bg,
                                        [cq]: ah
                                      },
                                      [cd]: n
                                    }, {
                                      [ce]: [aF, aD],
                                      endpoint: {
                                        [cj]: "https://{accessPointName}-{bucketArn#accountId}.s3-accesspoint.{bucketArn#region}.{bucketPartition#dnsSuffix}",
                                        [ck]: bg,
                                        [cq]: ah
                                      },
                                      [cd]: n
                                    }],
                                    [cd]: o
                                  }, ba],
                                  [cd]: o
                                }, bb],
                                [cd]: o
                              }, {
                                error: "Invalid ARN: The ARN was not for the S3 service, found: {bucketArn#service}",
                                [cd]: e
                              }],
                              [cd]: o
                            }, bc],
                            [cd]: o
                          }, bd],
                          [cd]: o
                        }],
                        [cd]: o
                      }],
                      [cd]: o
                    }, be],
                    [cd]: o
                  }],
                  [cd]: o
                }],
                [cd]: o
              }, {
                [ce]: [{
                  [cf]: x,
                  [cg]: [aS, b]
                }],
                rules: [{
                  [ce]: by,
                  error: "S3 MRAP does not support dual-stack",
                  [cd]: e
                }, {
                  [ce]: bF,
                  error: "S3 MRAP does not support FIPS",
                  [cd]: e
                }, {
                  [ce]: bz,
                  error: "S3 MRAP does not support S3 Accelerate",
                  [cd]: e
                }, {
                  [ce]: [{
                    [cf]: d,
                    [cg]: [{
                      [ch]: "DisableMultiRegionAccessPoints"
                    }, b]
                  }],
                  error: "Invalid configuration: Multi-Region Access Point ARNs are disabled.",
                  [cd]: e
                }, {
                  [ce]: [{
                    [cf]: f,
                    [cg]: bv,
                    [ci]: L
                  }],
                  rules: [{
                    [ce]: [{
                      [cf]: g,
                      [cg]: [{
                        [cf]: h,
                        [cg]: [{
                          [ch]: L
                        }, i]
                      }, {
                        [cf]: h,
                        [cg]: [aP, "partition"]
                      }]
                    }],
                    rules: [{
                      endpoint: {
                        [cj]: "https://{accessPointName}.accesspoint.s3-global.{mrapPartition#dnsSuffix}",
                        [ck]: {
                          [cm]: [{
                            [cn]: b,
                            name: "sigv4a",
                            [co]: z,
                            signingRegionSet: ["*"]
                          }]
                        },
                        [cq]: ah
                      },
                      [cd]: n
                    }],
                    [cd]: o
                  }, {
                    error: "Client was configured for partition `{mrapPartition#name}` but bucket referred to partition `{bucketArn#partition}`",
                    [cd]: e
                  }],
                  [cd]: o
                }],
                [cd]: o
              }, {
                error: "Invalid Access Point Name",
                [cd]: e
              }],
              [cd]: o
            }, bf],
            [cd]: o
          }, {
            [ce]: [{
              [cf]: g,
              [cg]: [aR, y]
            }],
            rules: [{
              [ce]: by,
              error: "S3 Outposts does not support Dual-stack",
              [cd]: e
            }, {
              [ce]: bF,
              error: "S3 Outposts does not support FIPS",
              [cd]: e
            }, {
              [ce]: bz,
              error: "S3 Outposts does not support S3 Accelerate",
              [cd]: e
            }, {
              [ce]: [{
                [cf]: c,
                [cg]: [{
                  [cf]: h,
                  [cg]: [aP, "resourceId[4]"]
                }]
              }],
              error: "Invalid Arn: Outpost Access Point ARN contains sub resources",
              [cd]: e
            }, {
              [ce]: [{
                [cf]: h,
                [cg]: bS,
                [ci]: w
              }],
              rules: [{
                [ce]: bL,
                rules: [aW, {
                  [ce]: bW,
                  rules: [{
                    [ce]: bN,
                    rules: [{
                      [ce]: bX,
                      rules: [{
                        [ce]: bY,
                        rules: [{
                          [ce]: bZ,
                          rules: [{
                            [ce]: [{
                              [cf]: h,
                              [cg]: bV,
                              [ci]: M
                            }],
                            rules: [{
                              [ce]: [{
                                [cf]: h,
                                [cg]: [aP, "resourceId[3]"],
                                [ci]: J
                              }],
                              rules: [{
                                [ce]: [{
                                  [cf]: g,
                                  [cg]: [{
                                    [ch]: M
                                  }, I]
                                }],
                                rules: [{
                                  [ce]: bA,
                                  endpoint: {
                                    [cj]: "https://{accessPointName}-{bucketArn#accountId}.{outpostId}.{url#authority}",
                                    [ck]: bh,
                                    [cq]: ah
                                  },
                                  [cd]: n
                                }, {
                                  endpoint: {
                                    [cj]: "https://{accessPointName}-{bucketArn#accountId}.{outpostId}.s3-outposts.{bucketArn#region}.{bucketPartition#dnsSuffix}",
                                    [ck]: bh,
                                    [cq]: ah
                                  },
                                  [cd]: n
                                }],
                                [cd]: o
                              }, {
                                error: "Expected an outpost type `accesspoint`, found {outpostType}",
                                [cd]: e
                              }],
                              [cd]: o
                            }, {
                              error: "Invalid ARN: expected an access point name",
                              [cd]: e
                            }],
                            [cd]: o
                          }, {
                            error: "Invalid ARN: Expected a 4-component resource",
                            [cd]: e
                          }],
                          [cd]: o
                        }, bb],
                        [cd]: o
                      }, bc],
                      [cd]: o
                    }, bd],
                    [cd]: o
                  }],
                  [cd]: o
                }],
                [cd]: o
              }, {
                error: "Invalid ARN: The outpost Id may only contain a-z, A-Z, 0-9 and `-`. Found: `{outpostId}`",
                [cd]: e
              }],
              [cd]: o
            }, {
              error: "Invalid ARN: The Outpost Id was not set",
              [cd]: e
            }],
            [cd]: o
          }, {
            error: "Invalid ARN: Unrecognized format: {Bucket} (type: {arnType})",
            [cd]: e
          }],
          [cd]: o
        }, {
          error: "Invalid ARN: No ARN type specified",
          [cd]: e
        }],
        [cd]: o
      }, {
        [ce]: [{
          [cf]: j,
          [cg]: [ab, 0, 4, a],
          [ci]: N
        }, {
          [cf]: g,
          [cg]: [{
            [ch]: N
          }, "arn:"]
        }, {
          [cf]: r,
          [cg]: [{
            [cf]: c,
            [cg]: [bi]
          }]
        }],
        error: "Invalid ARN: `{Bucket}` was not a valid ARN",
        [cd]: e
      }, {
        [ce]: [{
          [cf]: d,
          [cg]: [au, b]
        }, bi],
        error: "Path-style addressing cannot be used with ARN buckets",
        [cd]: e
      }, {
        [ce]: bD,
        rules: [{
          [ce]: bN,
          rules: [{
            [ce]: [av],
            rules: [{
              [ce]: [W, an, V, aw],
              endpoint: {
                [cj]: "https://s3-fips.dualstack.us-east-1.{partitionResult#dnsSuffix}/{uri_encoded_bucket}",
                [ck]: ax,
                [cq]: ah
              },
              [cd]: n
            }, {
              [ce]: [W, an, V, ay, az],
              rules: [{
                endpoint: bj,
                [cd]: n
              }],
              [cd]: o
            }, {
              [ce]: [W, an, V, ay, aC],
              endpoint: bj,
              [cd]: n
            }, {
              [ce]: [aD, an, V, aw],
              endpoint: {
                [cj]: "https://s3-fips.us-east-1.{partitionResult#dnsSuffix}/{uri_encoded_bucket}",
                [ck]: ax,
                [cq]: ah
              },
              [cd]: n
            }, {
              [ce]: [aD, an, V, ay, az],
              rules: [{
                endpoint: bk,
                [cd]: n
              }],
              [cd]: o
            }, {
              [ce]: [aD, an, V, ay, aC],
              endpoint: bk,
              [cd]: n
            }, {
              [ce]: [W, an, aF, aw],
              endpoint: {
                [cj]: "https://s3.dualstack.us-east-1.{partitionResult#dnsSuffix}/{uri_encoded_bucket}",
                [ck]: ax,
                [cq]: ah
              },
              [cd]: n
            }, {
              [ce]: [W, an, aF, ay, az],
              rules: [{
                endpoint: bl,
                [cd]: n
              }],
              [cd]: o
            }, {
              [ce]: [W, an, aF, ay, aC],
              endpoint: bl,
              [cd]: n
            }, {
              [ce]: [aD, X, ac, aF, aw],
              endpoint: {
                [cj]: O,
                [ck]: ax,
                [cq]: ah
              },
              [cd]: n
            }, {
              [ce]: [aD, X, ac, aF, ay, az],
              rules: [{
                [ce]: bP,
                endpoint: bm,
                [cd]: n
              }, {
                endpoint: bm,
                [cd]: n
              }],
              [cd]: o
            }, {
              [ce]: [aD, X, ac, aF, ay, aC],
              endpoint: bm,
              [cd]: n
            }, {
              [ce]: [aD, an, aF, aw],
              endpoint: {
                [cj]: P,
                [ck]: ax,
                [cq]: ah
              },
              [cd]: n
            }, {
              [ce]: [aD, an, aF, ay, az],
              rules: [{
                [ce]: bP,
                endpoint: {
                  [cj]: P,
                  [ck]: aB,
                  [cq]: ah
                },
                [cd]: n
              }, {
                endpoint: bn,
                [cd]: n
              }],
              [cd]: o
            }, {
              [ce]: [aD, an, aF, ay, aC],
              endpoint: bn,
              [cd]: n
            }],
            [cd]: o
          }, {
            error: "Path-style addressing cannot be used with S3 Accelerate",
            [cd]: e
          }],
          [cd]: o
        }],
        [cd]: o
      }],
      [cd]: o
    }, {
      [ce]: [{
        [cf]: c,
        [cg]: [bo]
      }, {
        [cf]: d,
        [cg]: [bo, b]
      }],
      rules: [{
        [ce]: bN,
        rules: [{
          [ce]: cb,
          rules: [aT, aU, {
            [ce]: bA,
            endpoint: {
              [cj]: s,
              [ck]: bp,
              [cq]: ah
            },
            [cd]: n
          }, {
            [ce]: bF,
            endpoint: {
              [cj]: "https://s3-object-lambda-fips.{Region}.{partitionResult#dnsSuffix}",
              [ck]: bp,
              [cq]: ah
            },
            [cd]: n
          }, {
            endpoint: {
              [cj]: "https://s3-object-lambda.{Region}.{partitionResult#dnsSuffix}",
              [ck]: bp,
              [cq]: ah
            },
            [cd]: n
          }],
          [cd]: o
        }, aO],
        [cd]: o
      }],
      [cd]: o
    }, {
      [ce]: [ap],
      rules: [{
        [ce]: bN,
        rules: [{
          [ce]: cb,
          rules: [{
            [ce]: [V, W, an, aw],
            endpoint: {
              [cj]: "https://s3-fips.dualstack.us-east-1.{partitionResult#dnsSuffix}",
              [ck]: ax,
              [cq]: ah
            },
            [cd]: n
          }, {
            [ce]: [V, W, an, ay, az],
            rules: [{
              endpoint: bq,
              [cd]: n
            }],
            [cd]: o
          }, {
            [ce]: [V, W, an, ay, aC],
            endpoint: bq,
            [cd]: n
          }, {
            [ce]: [V, aD, an, aw],
            endpoint: {
              [cj]: "https://s3-fips.us-east-1.{partitionResult#dnsSuffix}",
              [ck]: ax,
              [cq]: ah
            },
            [cd]: n
          }, {
            [ce]: [V, aD, an, ay, az],
            rules: [{
              endpoint: br,
              [cd]: n
            }],
            [cd]: o
          }, {
            [ce]: [V, aD, an, ay, aC],
            endpoint: br,
            [cd]: n
          }, {
            [ce]: [aF, W, an, aw],
            endpoint: {
              [cj]: "https://s3.dualstack.us-east-1.{partitionResult#dnsSuffix}",
              [ck]: ax,
              [cq]: ah
            },
            [cd]: n
          }, {
            [ce]: [aF, W, an, ay, az],
            rules: [{
              endpoint: bs,
              [cd]: n
            }],
            [cd]: o
          }, {
            [ce]: [aF, W, an, ay, aC],
            endpoint: bs,
            [cd]: n
          }, {
            [ce]: [aF, aD, X, ac, aw],
            endpoint: {
              [cj]: s,
              [ck]: ax,
              [cq]: ah
            },
            [cd]: n
          }, {
            [ce]: [aF, aD, X, ac, ay, az],
            rules: [{
              [ce]: bP,
              endpoint: bt,
              [cd]: n
            }, {
              endpoint: bt,
              [cd]: n
            }],
            [cd]: o
          }, {
            [ce]: [aF, aD, X, ac, ay, aC],
            endpoint: bt,
            [cd]: n
          }, {
            [ce]: [aF, aD, an, aw],
            endpoint: {
              [cj]: Q,
              [ck]: ax,
              [cq]: ah
            },
            [cd]: n
          }, {
            [ce]: [aF, aD, an, ay, az],
            rules: [{
              [ce]: bP,
              endpoint: {
                [cj]: Q,
                [ck]: aB,
                [cq]: ah
              },
              [cd]: n
            }, {
              endpoint: bu,
              [cd]: n
            }],
            [cd]: o
          }, {
            [ce]: [aF, aD, an, ay, aC],
            endpoint: bu,
            [cd]: n
          }],
          [cd]: o
        }, aO],
        [cd]: o
      }],
      [cd]: o
    }],
    [cd]: o
  }, {
    error: "A region must be set when sending requests to S3.",
    [cd]: e
  }]
};
export const ruleSet = _data;